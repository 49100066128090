import * as React from 'react';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Products } from 'components/Products';
import { ContactUs } from 'components/ContactUs';

const CatalogPage = () => (
  <Layout>
    <SEO title="Каталог" />
    <Breadcrumbs label="Каталог" className="mt-12 mb-16 mlg:mt-10 mlg:mb-12 mmd:mt-8 mmd:mb-10 msm:mb-8" />
    <Products className="mb-16 mlg:mb-12 mmd:mb-10" />
    <ContactUs />
  </Layout>
);

export default CatalogPage;
