import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import cn from 'classnames';
import { productsData } from 'data/products';
import { ProductCard } from 'components/ProductCard';
import s from './Products.module.css';

type Props = {
  readonly indexPage?: boolean;
  readonly className?: string;
};

export const Products = ({ indexPage, className }: Props) => {
  const data = useStaticQuery<GatsbyTypes.ProductsQuery>(graphql`
    query ProductsQuery {
      allFile(filter: { sourceInstanceName: { eq: "productsImages" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 267) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return (
    <div className={cn('container', className)}>
      <div className={s.title}>Предлагаемые товарные позиции</div>
      <div className={s.products}>
        {productsData.map((p) => {
          const file = data.allFile.nodes.find((f) => f.name === p.slug);

          return (
            <ProductCard
              key={p.name}
              data={p}
              image={file?.childImageSharp?.fluid}
              className={cn(s.product, indexPage && s.indexPage)}
            />
          );
        })}
      </div>
      {indexPage && (
        <Link to="/catalog" className={cn('button button-normal button-ghost', s.button)}>
          Смотреть все продукты
        </Link>
      )}
    </div>
  );
};

Products.defaultProps = {
  indexPage: false,
  className: undefined,
};
