/* eslint-disable max-len */
import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import topLine from './contactUsTop.svg';
import bottomLine from './contactUsBottom.svg';
import { PhoneLink } from './PhoneLink';
import s from './ContactUs.module.css';

export const ContactUs = () => (
  <>
    <img src={topLine} alt="" className="w-full -mb-px" />
    <div className={s.root}>
      <div className="container flex flex-col items-center text-center">
        <div className={s.title}>Запросите образец или спросите о наших рекламных идеях</div>
        <div className="text-2xl leading-7 mb-16 mxl:text-xl mxl:leading-6 mxl:mb-12 mlg:text-base mlg:leading-5 mlg:mb-8 mmd:text-sm mmd:leading-4 mmd:mb-6 msm:mb-8">
          <div>Звоните или оставляйте заявку у нас на сайте.</div>
          <div>Также по указанному номеру вы можете связаться с нами через любой удобный вам мессенджер.</div>
        </div>
        <div className="flex msm:flex-col">
          <PhoneLink className={cn('button button-normal button-white', s.button)} />
          <Link to="/contacts" className={cn('button button-normal button-green', s.button)}>
            Связаться с нами
          </Link>
        </div>
      </div>
    </div>
    <img src={bottomLine} alt="" className="w-full -mt-px" />
  </>
);
