import * as React from 'react';
import Img from 'gatsby-image';
import cn from 'classnames';
import { Product } from 'data/products';
import { countriesData } from 'data/countries';
import { ModalButton } from './ModalLogic';
import { Icon } from './icons';
import s from './ProductCard.module.css';

type Props = {
  readonly data: Product;
  readonly image: GatsbyTypes.GatsbyImageSharpFluid_NoBase64Fragment | null | undefined;
  readonly className: string;
};

export const ProductCard = ({ data, image, className }: Props) => {
  if (data.status === 'd') return null;

  const country = countriesData[data.countryCode];

  return (
    <ModalButton to="product" qp={`&name=${data.name}`} className={cn(s.root, className)}>
      <Icon name={country.iconName} className={s.flag} />
      {/* @ts-ignore */}
      {image && <Img fluid={image} alt={data.name} />}
      <div className={cn(s.content, !image && 'mt-auto')}>
        <div className={s.name}>{data.name}</div>
        <div className={s.description}>{data.shortDescription}</div>
      </div>
    </ModalButton>
  );
};
